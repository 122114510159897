import ErrorLayout from "@r8er/layout/error";
import { useEffect, useState } from "react";

const NotFound = () => {
  const [isApp, setIsApp] = useState(false);
  useEffect(() => setIsApp(window.location.pathname.startsWith("/app")));
  return <ErrorLayout title="Page not found" isApp={isApp} className="" />;
};

export default NotFound;
